import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import Accordion from '../components/Accordion/Accordion';

const faqData = [
    {
        id: 0,
        title: 'faq_page_title_1',
        text: 'faq_page_text_1'
    },
    {
        id: 1,
        title: 'faq_page_title_2',
        text: 'faq_page_text_2'
    },
    {
        id: 2,
        title: 'faq_page_title_3',
        text: 'faq_page_text_3'
    },
    {
        id: 3,
        title: 'faq_page_title_4',
        text: 'faq_page_text_4'
    },
    {
        id: 4,
        title: 'faq_page_title_5',
        text: 'faq_page_text_5'
    },

];

const Faq = () => {
    const [collapsedId, setCollapsedId] = useState(null);
    const {t} = useTranslation();

    const handleAccordionToggle = id => {
        setCollapsedId(collapsedId === id ? null : id);
    };

    return (
        <PageWrapper title={t('faq_page_title')}
                     className="page-wrapper"
                     background={'linear-gradient(0deg, rgba(2,2,30,1) 0%,  rgba(30,30,61,1) 100%)'}
        >
            <>
                {faqData.map(faq => (
                    <Accordion key={faq.id}
                               title={t(faq.title)}
                               text={t(faq.text)}
                               isCollapsed={collapsedId === faq.id}
                               onClick={() => handleAccordionToggle(faq.id)}
                    />
                ))}
            </>
        </PageWrapper>
    );
};

export default Faq;