import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import Button from '../Button/Button';
import './style.scss';
import {useState} from 'react';
import classNames from 'classnames';

function RegisterModal({setShowRegisterModal}) {
    const { t } = useTranslation();

    const [blowDownModalAnimation, setBlowDownModalAnimation] = useState(false);

    const closeModalsHandler = () => {
        setBlowDownModalAnimation(true);

        setTimeout(() => {
            setShowRegisterModal(false);
        }, 300);
    };

    const handleArmeniaClick = () => {
        window.open('https://affiliate.apartners.club/#/affiliate/register/3', '_blank', 'noopener,noreferrer');
    };

    const handleWorldwideClick = () => {
        window.open('https://affiliate.apartners.club/#/affiliate/register/6', '_blank', 'noopener,noreferrer');
    };

    return (
        <Modal
            title={t('register')}
            closeBtnClickHandler={closeModalsHandler}
            hasHeader={true}
            className={classNames('modal-register modal-xs', {
                'blow-down-modal': blowDownModalAnimation
            })}
        >
            <p>{t('choose_partner_geo')}</p>
            <Button primary
                    lg
                    fullWidth
                    text={t('armenia')}
                    onClick={handleArmeniaClick}
            />
            <Button primary
                    lg
                    fullWidth
                    text={t('worldwide')}
                    onClick={handleWorldwideClick}
            />
        </Modal>
    );
}

RegisterModal.propTypes = {
    setShowRegisterModal: PropTypes.func
};


export default RegisterModal;