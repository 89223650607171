import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SectionWrapper.module.scss';

const SectionWrapper = ({ title, children, className }) => {
    return (
        <section className={classnames(styles['section-wrapper'], {
            [className]: className
        })}>

            <div className="container">
                {title &&
                    <h1 className={`title ${styles['title']}`}>{title}</h1>
                }
                {children}
            </div>
        </section>
    );
};

SectionWrapper.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    className: PropTypes.string
};

export default SectionWrapper;
