import { useTranslation } from 'react-i18next';
import PageWrapper from '../components/PageWrapper/PageWrapper';

const Terms = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper title={t('terms_and_conditions')}
                     className="terms"
                     background={'linear-gradient(0deg, rgba(2,2,30,1) 0%,  rgba(16,42,78,1) 100%)'}
        >
            <div  dangerouslySetInnerHTML={{__html: t('terms_text')}}/>
        </PageWrapper>
    );
};

export default Terms;