import {Suspense, useState} from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Faq from './pages/Faq';
import Commission from './pages/Commision';
import Offers from './pages/Offers';
import Casinos from './pages/Casinos';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import './App.scss';
import ContactUsModal from './components/Modal/ContactUsModal';
import RegisterModal from './components/Modal/RegisterModal';

function App() {
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);

  return (
    <Suspense fallback="loading">
      <div className="App">
        <Router>
            <Header setShowRegisterModal={setShowRegisterModal} />
            <Routes>
                <Route exact path="/" element={<Home setShowContactUsModal={setShowContactUsModal} setShowRegisterModal={setShowRegisterModal} />} />
                <Route path="/about" element={<About />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/commision" element={<Commission />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/casinos" element={<Casinos />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
            <Footer setShowContactUsModal={setShowContactUsModal} />

            {showContactUsModal && <ContactUsModal setShowContactUsModal={setShowContactUsModal} />}
            {showRegisterModal && <RegisterModal setShowRegisterModal={setShowRegisterModal} />}

        </Router>
      </div>
    </Suspense>
  );
}

export default App;
