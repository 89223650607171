// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SectionWrapper from '../../components/SectionWrapper/SectionWrapper';
import Button from '../../components/Button/Button';
import styles from './OurPartners.module.scss';

function OurPartners() {
    const { t } = useTranslation();

    return (
        <SectionWrapper className={`${styles['our-partners']} test`} title={t('our_partners_title')}>
            <div className={styles['partners-item-wrapper']}>
                <div className={styles['partners-item']}>
                    <img src="./images/partners/goodwin-am.png" alt="" className={styles['item-img']} />
                    <div className={styles['item-content']}>
                        <div>
                            <img src="./images/partners/am-logo.png" alt="goodwin.am" className={styles['item-logo']} />
                            <p className={styles['item-text']}>{t('partners1_text')}</p>
                        </div>
                        <Button as="a" to="https://goodwin.am/" target="_blank" text={t('go_to_website')} primaryOutline />
                    </div>
                </div>

                <div className={styles['partners-item']}>
                    <img src="./images/partners/goodwin-win.png" alt="" className={styles['item-img']} />
                    <div className={styles['item-content']}>
                        <div>
                            <img src="./images/partners/win-logo.png" alt="goodwin.win" className={styles['item-logo']} />
                            <p className={styles['item-text']}>{t('partners2_text')}</p>
                        </div>
                        <Button as="a" to="https://goodwin1.win/" target="_blank" text={t('go_to_website')} primaryOutline />
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
}

export default OurPartners;