import {useTranslation} from 'react-i18next';
import SectionWrapper from '../../components/SectionWrapper/SectionWrapper';
import Button from '../../components/Button/Button';
import data from './data.json';
import styles from './WhyUs.module.scss';
import PropTypes from 'prop-types';

const WhyUs = ({setShowRegisterModal}) => {
    const { t } = useTranslation();

    return (
        <SectionWrapper title={t('why_us')} className={styles['why-us']}>
            <div className={styles['why-us-item-wrapper']}>
                {data.WHY_US_ITEMS.map(item => (
                    <div key={item.id} className={`${styles['why-us-item']}`} style={{backgroundImage: item.image}}>
                        <h3 className={styles.title}>{t(item.title)}</h3>
                        <p className={styles.text}>{t(item.text)}</p>
                    </div>
                ))}
            </div>
            <Button primary lg text={t('become_a_partner')} onClick={() => setShowRegisterModal(true)} />
        </SectionWrapper>
    );
};

WhyUs.propTypes = {
    setShowRegisterModal: PropTypes.func
};

export default WhyUs;
