import { useTranslation } from 'react-i18next';
import PageWrapper from '../components/PageWrapper/PageWrapper';

const Casinos = () => {
    const { t } = useTranslation();
        
    return (
        <PageWrapper className="col-2"
                     background={'linear-gradient(0deg, rgb(2, 2, 30) 0%, rgba(15,43,72,1) 100%)'}>

            <div className="inner-content">
                <h1 className="page-wrapper-title">{t('casinos_page_title')}</h1>
                <p>{t('casinos_page_text')}</p>
            </div>
            <img src="./images/pages/casino.svg" alt="casino" className="inner-content-img" loading="lazy"/>
        </PageWrapper>
    );
};

export default Casinos;