import {useState} from 'react';
import PropTypes from 'prop-types';
import '../../config/i18n';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { IS_MOBILE_SIZE } from '../../helpers/constant';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import Button from '../Button/Button';
import './style.scss';

function Header({setShowRegisterModal}) {
    const { t } = useTranslation();
    const [burger, setBurger] = useState(false);

    const isMobile = useMediaQuery({ query: IS_MOBILE_SIZE });

    const handleArmeniaClick = () => {
        window.open('https://affiliate.apartners.club/#/affiliate/register/3', '_blank', 'noopener,noreferrer');
    };

    const handleWorldwideClick = () => {
        window.open('https://affiliate.apartners.club/#/affiliate/register/6', '_blank', 'noopener,noreferrer');
    };

    return (
        <header>
            <div className="d-flex align-items-center">
                <Link to="/">
                    <img src="./images/logo.png" alt="logo" className="logo" />
                </Link>
                <Link to="https://t.me/alphapartners_club" className="telegram-icon">
                    <img src="./images/telegram.png" alt="logo" className="telegram" />
                </Link>
            </div>
            <nav>
                <div className={`burger ${burger ? 'close' : ''}`} onClick={() => setBurger(prev => !prev)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <ul>
                    <li>
                        <NavLink to="/casinos" onClick={() => setBurger(false)}>{t('casinos_page')}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/offers" onClick={() => setBurger(false)}>{t('offers_page')}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/commision" onClick={() => setBurger(false)}>{t('commission_page')}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/faq" onClick={() => setBurger(false)}>{t('faq_page')}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" onClick={() => setBurger(false)}>{t('about_us_page')}</NavLink>
                    </li>
                </ul>

                <LanguageSwitcher />

            </nav>

            <div className="buttons-wrapper d-flex align-items-center">
                    <Button as="a"
                            to="https://affiliate.apartners.club"
                            target="_blank"
                            primaryOutline
                            text={t('log_in_button')}
                    />
                    <div className="register-tooltip-wrapper">

                        {isMobile ?
                            <Button primary text={t('register')} className="btn-register" onClick={() => setShowRegisterModal(true)}/>
                            :

                            <>
                                <Button primary text={t('register')} className="btn-register" />
                                <div className="register-tooltip">
                                    <div className="register-tooltip-inner">
                                        <Button primary
                                                fullWidth
                                                text={t('armenia')}
                                                onClick={handleArmeniaClick}
                                        />
                                        <Button primary
                                                fullWidth
                                                text={t('worldwide')}
                                                onClick={handleWorldwideClick}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
        </header>
    );
}

Header.propTypes = {
    setShowRegisterModal: PropTypes.func
};

export default Header;