import PropTypes from 'prop-types';
import styles from './Accordion.module.scss';

function Accordion({title, text, isCollapsed, onClick}) {
    return (
        <div className={`${styles.accordion} ${isCollapsed ? styles.opened : styles.closed}`} onClick={onClick}>
            <div className={styles['accordion-header']}>
                <h3 className={styles['accordion-title']}>{title}</h3>
                <button className={styles['accordion-btn']}>
                    <img src="./images/icons/arrow-down.svg" alt="arrow down" />
                </button>
            </div>
            <div className={styles['accordion-content']}>
                {text}
            </div>
        </div>
    );
}

Accordion.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    isCollapsed: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Accordion;