import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const browserLanguage = navigator.language.split('-')[0];

    const [showLang, setShowLang] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem('selectedLanguage') || browserLanguage
    );

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
        setShowLang(false);
    };

    useEffect(() => {
        localStorage.setItem('selectedLanguage', selectedLanguage);
    }, [selectedLanguage]);

    const languages = Object.keys(i18n.options.resources);

    return (
        <div className="language-switcher">
            <div className="choosed" onClick={() => setShowLang(prev => !prev)}>
                <img src={`./images/${i18n.language}.png`} alt={i18n.language} />
                <span>{i18n.language}</span>
            </div>

            {showLang &&
                <div className="list">
                    {languages.map((lang) => (
                        <div key={lang} onClick={() => changeLanguage(lang)}>
                            <img src={`./images/${lang}.png`} alt={lang} />
                            <span> {lang}</span>
                        </div>
                    ))}

                </div>
            }
        </div>
    );
};

export default LanguageSwitcher;
