import { useTranslation } from 'react-i18next';
import SectionWrapper from '../../components/SectionWrapper/SectionWrapper';
import styles from './Network.module.scss';

function Network() {
    const { t } = useTranslation();

    return (
        <SectionWrapper className={styles['network']} title={t('network_title')}>
            <div className="d-flex flex-column align-items-center">
                <img src="./images/map.png" alt="map" className={styles['network-img']} />

                <ul className={styles['network-list']}>
                    <li className={styles['list-item']}>
                        <p>10 +</p>
                        <p className="text-muted">{t('languages')}</p>
                    </li>
                    <li className={styles['list-item']}>
                        <p>15 +</p>
                        <p className="text-muted">{t('countries')}</p>
                    </li>
                    <li className={styles['list-item']}>
                        <p>50,000 +</p>
                        <p className="text-muted">{t('daily_players')}</p>
                    </li>
                </ul>
            </div>
        </SectionWrapper>
    );
}

export default Network;