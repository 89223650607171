import {useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';
import classNames from 'classnames';
import Modal from './Modal';
import './style.scss';

function ContactUsModal({setShowContactUsModal}) {
    const form = useRef();

    const { t } = useTranslation();
    const [textareaValue, setTextareaValue] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [blowDownModalAnimation, setBlowDownModalAnimation] = useState(false);

    const textareaHandler = (e) => {
        setTextareaValue(e.target.value);
    };

    const submitHandler = (event) => {
        event.preventDefault();

            emailjs
                .sendForm(
                    'service_h5upnw8',
                    'template_p9741tm',
                    form.current,
                    '0IVN1X6qu4gieUac_'
                )
                .then((result) => {
                    console.log(result);
                }),
                (error) => {
                    console.log(error);
                };

        event.target.reset();
        setConfirmModal(true);
    };

    const closeModalsHandler = () => {
        setBlowDownModalAnimation(true);

        setTimeout(() => {
            setShowContactUsModal(false);
        }, 300);
    };

    return (
        <Modal
            title={t('contact_us')}
            closeBtnClickHandler={closeModalsHandler}
            hasHeader={!confirmModal}
            className={classNames('modal-contact', {
                'modal-confirm': confirmModal,
                'blow-down-modal': blowDownModalAnimation
            })}
        >
            {confirmModal ?
                <div className="modal-confirm-content">
                    <img src="./images/confirm-icon.svg" alt="confirm" className="confirm-icon" />
                    <p className="confirm-text">{t('confirm_contact_modal_text')}</p>
                    <button className="btn btn-xs btn-primary modal-footer-btn" onClick={closeModalsHandler}>
                        {t('ok')}
                    </button>
                </div>
                :
                <form action="src/components/Modal/ContactUsModal" className="form" ref={form} onSubmit={submitHandler}>
                    <div className="form-item">
                        <input type="text" placeholder=" " name="first_name" className="form-control" id="first_name" required />
                        <label className="form-label" htmlFor="first_name">{t('first_name')}</label>
                    </div>

                    <div className="form-item">
                        <input type="text" placeholder=" " name="username" className="form-control" id="username" required />
                        <label className="form-label" htmlFor="username">{t('username')}</label>
                    </div>

                    <div className="form-item">
                    <textarea required
                              name="message"
                              placeholder=" "
                              className="form-control textarea"
                              id="message"
                              maxLength={1000}
                              value={textareaValue}
                              onChange={textareaHandler} />
                        <label className="form-label" htmlFor="message">{t('question')}</label>
                    </div>

                    <input className="btn btn-primary modal-footer-btn" type="submit" value={t('send')} />
                </form>
            }

        </Modal>
    );
}

ContactUsModal.propTypes = {
    setShowContactUsModal: PropTypes.func
};


export default ContactUsModal;