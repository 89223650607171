import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './PageWrapper.module.scss';

const PageWrapper = ({title, background = 'linear-gradient(0deg, rgba(2,2,30,1) 0%,  rgba(30,30,61,1) 100%)', className, children}) => {
    return (
        <section className={classnames(styles['page-wrapper'], {
            [className]: className
        })}
                 style={{background: background}}>
            <div className={styles['page-wrapper-inner']}>
                {title &&
                    <h1 className="page-wrapper-title">{title}</h1>
                }

                {children}
            </div>
        </section>
    );
};

PageWrapper.propTypes = {
    title: PropTypes.string,
    background: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.element.isRequired
};

export default PageWrapper;