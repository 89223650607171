import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '../Button/Button';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './style.scss';

import {Pagination, Navigation, Autoplay} from 'swiper/modules';


function Slider({setShowRegisterModal}) {
    const {t} = useTranslation();

    return (
        <Swiper
            // pagination={{type: 'fraction'}}
            pagination={false}
            navigation={true}
            autoplay={{
                delay: 3500,
                disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            loop={true}
            className="slider"
        >
            <SwiperSlide>
                <img
                    srcSet={`./images/carousel/mobile-slide2-${useTranslation().i18n.language}.png 375w, ./images/carousel/desktop-slide2-${useTranslation().i18n.language}.png 1920w`}
                    sizes="(min-width: 900px) 1920px, 100vw"
                    src={`./images/carousel/desktop-slide2-${useTranslation().i18n.language}.png`}
                    alt="slide1"
                    className="slide-img"
                />

                <div className="slide-content">
                    <Button primary
                            lg
                            text={t('register')}
                            onClick={() => setShowRegisterModal(true)}
                    />
                    <img src="./images/alfaxgoodwin.png" alt="alfaxgoodwin" className="alpha-goodwin"/>
                </div>
            </SwiperSlide>
        </Swiper>
    );
}

Slider.propTypes = {
    setShowContactUsModal: PropTypes.func,
    setShowRegisterModal: PropTypes.func
};

export default Slider;