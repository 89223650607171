import {useState} from 'react';
import { useTranslation } from 'react-i18next';
import SectionWrapper from '../../components/SectionWrapper/SectionWrapper';
import Button from '../../components/Button/Button';
import styles from './Revenue.module.scss';
import PropTypes from 'prop-types';

function Revenue({setShowRegisterModal}) {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <SectionWrapper className={`${styles.revenue} tab-${activeTab + 1}`}>
            <nav className={styles['nav-tabs']}>
                <Button className={`${styles['nav-item']} ${activeTab === 0 ? styles.active : ''}`}
                        text={t('revenue_share')}
                        onClick={() => handleTabClick(0)}
                />
                <Button className={`${styles['nav-item']} ${activeTab === 1 ? styles.active : ''}`}
                        text={t('cpa')}
                        onClick={() => handleTabClick(1)}
                />
                <Button className={`${styles['nav-item']} ${activeTab === 2 ? styles.active : ''}`}
                        text={t('hyprid')}
                        onClick={() => handleTabClick(2)}
                />
                <Button className={`${styles['nav-item']} ${activeTab === 3 ? styles.active : ''}`}
                        text={t('sub_affiliate')}
                        onClick={() => handleTabClick(3)}
                />
            </nav>
            <div className={styles['tab-content']}>
                    <div className={`${styles.tab} ${activeTab === 0 ? styles.active : ''}`}>
                        <div className={styles['tab-text']} dangerouslySetInnerHTML={{__html: t('revenue_share_content')}} />

                        <div className={styles['share-wrapper']}>
                            <div className={styles['share-item']}>
                                <h3 className={styles['percent']}>35%</h3>
                                <div className="mt-auto">
                                    <h4 className={styles['number']}>0-10</h4>
                                    <p className={styles['ftd-mouth']}>{t('ftd_mouth')}</p>
                                </div>
                            </div>
                            <div className={styles['share-item']}>
                                <h3 className={styles['percent']}>40%</h3>
                                <div className="mt-auto">
                                    <h4 className={styles['number']}>11-20</h4>
                                    <p className={styles['ftd-mouth']}>{t('ftd_mouth')}</p>
                                </div>
                            </div>
                            <div className={styles['share-item']}>
                                <h3 className={styles['percent']}>45%</h3>
                                <div className="mt-auto">
                                    <h4 className={styles['number']}>21-50</h4>
                                    <p className={styles['ftd-mouth']}>{t('ftd_mouth')}</p>
                                </div>
                            </div>
                            <div className={styles['share-item']}>
                                <h3 className={styles['percent']}>50%</h3>
                                <div className="mt-auto">
                                    <h4 className={styles['number']}>51-100</h4>
                                    <p className={styles['ftd-mouth']}>{t('ftd_mouth')}</p>
                                </div>
                            </div>
                            <div className={styles['share-item']}>
                                <h3 className={styles['percent']}>55%</h3>
                                <div className="mt-auto">
                                    <h4 className={styles['number']}>101+</h4>
                                    <p className={styles['ftd-mouth']}>{t('ftd_mouth')}</p>
                                </div>
                            </div>
                        </div>

                        <Button primary lg text={t('become_a_partner')} onClick={() => setShowRegisterModal(true)} />
                    </div>
                    <div className={`${styles.tab} ${activeTab === 1 ? styles.active : ''}`}>
                        <div className={styles['tab-text']} dangerouslySetInnerHTML={{__html: t('cpa_content')}} />

                        <div className={styles['cpa-info']}>
                            <p className={styles['info-text']}>{t('no_hold_on_cpa')}</p>
                            <p className={styles['info-text']}>{t('up_to_right_away')}</p>
                        </div>

                        <Button primary lg text={t('become_a_partner')} onClick={() => setShowRegisterModal(true)} />
                    </div>
                    <div className={`${styles.tab} ${activeTab === 2 ? styles.active : ''}`}>
                        <div className={styles['tab-text']} dangerouslySetInnerHTML={{__html: t('hybrid_content')}} />
                        <Button primary lg text={t('become_a_partner')} onClick={() => setShowRegisterModal(true)} />
                    </div>
                    <div className={`${styles.tab} ${activeTab === 3 ? styles.active : ''}`}>
                        <div className={styles['tab-text']} dangerouslySetInnerHTML={{__html: t('sub_affiliate_content')}} />
                        <Button primary lg text={t('become_a_partner')} onClick={() => setShowRegisterModal(true)} />
                    </div>
                </div>
        </SectionWrapper>
    );
}

Revenue.propTypes = {
    setShowRegisterModal: PropTypes.func
};

export default Revenue;