import {useTranslation} from 'react-i18next';
import PageWrapper from '../components/PageWrapper/PageWrapper';

const About = () => {
    const {t} = useTranslation();

    return (
        <PageWrapper className="col-2"
                     background={'linear-gradient(0deg, rgb(2, 2, 30) 0%, rgba(36,2,23,1) 100%)'}>
            <div className="inner-content">
                <h1 className="page-wrapper-title">{t('about_page_title')}</h1>
                <p>{t('about_page_text')}</p>
            </div>
            <img src="./images/pages/about.svg" alt="about" className="inner-content-img" loading="lazy"/>
        </PageWrapper>
    );
};

export default About;