import PropTypes from 'prop-types';
import Slider from '../components/Slider/Slider';
import Revenue from '../components/Revenue/Revenue';
import OurPartners from '../components/OurPartners/OurPartners';
import Contact from '../components/Contact/Contact';
import Network from '../components/Network/Network';
import WhyUs from '../components/WhyUs/WhyUs';

const Home = ({setShowContactUsModal, setShowRegisterModal}) => {
    return (
        <div className="page-home">
            <Slider setShowRegisterModal={setShowRegisterModal} />
            <Revenue setShowRegisterModal={setShowRegisterModal} />
            <WhyUs setShowRegisterModal={setShowRegisterModal}  />
            <Network />
            <OurPartners />
            <Contact setShowContactUsModal={setShowContactUsModal} />
        </div>
    );
};

Home.propTypes = {
    setShowContactUsModal: PropTypes.func,
    setShowRegisterModal: PropTypes.func
};
 
export default Home;