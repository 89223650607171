import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Button.module.scss';

const Button = ({ as='button',
                  text,
                  primary, primaryOutline, secondary,
                  xs, lg,
                  fullWidth,
                  className,
                  onClick,
                  to, target }) => {

    const Component = as === 'a' ? RouterLink : as;

    return (
        <Component className={classnames('btn', styles.btn, {
                    [className]: className,
                    [styles['btn-primary']]: primary,
                    [styles['btn-primary-outline']]: primaryOutline,
                    [styles['btn-secondary']]: secondary,
                    [styles['btn-xs']]: xs,
                    [styles['btn-lg']]: lg,
                    [styles['full-width']]: fullWidth
                   })}
                   to={to}
                   target={target}
                   onClick={onClick}
        >
            {text}
        </Component>
    );
};

Button.propTypes = {
    as: PropTypes.string,
    text: PropTypes.string,
    primary: PropTypes.bool,
    primaryOutline: PropTypes.bool,
    secondary: PropTypes.bool,
    xs: PropTypes.bool,
    lg: PropTypes.bool,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string,
    target: PropTypes.string
};

export default Button;
