import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.scss';
import {useMediaQuery} from 'react-responsive';
import {IS_TABLET_SIZE} from '../../helpers/constant';
import PropTypes from 'prop-types';

function Footer({setShowContactUsModal}) {
    const { t } = useTranslation();
    const isTablet = useMediaQuery({ query: IS_TABLET_SIZE });

    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={styles['footer-top']}>
                    {!isTablet &&
                        <Link to="/" >
                            <img src="./images/logo.png" alt="logo" className={styles.logo} />
                        </Link>
                    }

                    <div className={styles['footer-links']}>
                        <NavLink to="/about">{t('about_us_page')}</NavLink>
                        <NavLink to="/terms">{t('terms_and_conditions')}</NavLink>
                        <NavLink to="/privacy">{t('privacy_policy')}</NavLink>
                        <NavLink to="/faq">{t('faq_page')}</NavLink>
                    </div>
                    <div className={styles['footer-contact']}>
                            <span>{t('contact_us')}:</span>
                            <p onClick={() => setShowContactUsModal(true)}>
                                <img src="./images/icons/email.svg" alt="mail-icon" className={styles['contact-icon']} />
                                {t('contact_email')}
                            </p>
                            <a href="https://t.me/alphapartners_club" target="_blank" rel="noreferrer">
                                <img src="./images/icons/telegram.svg" alt="telegram-icon" className={styles['contact-icon']} />
                                {t('telegram_name')}
                            </a>
                        </div>
                </div>

                {isTablet &&
                    <Link to="/" >
                        <img src="./images/logo.png" alt="logo" className={styles.logo} />
                    </Link>
                }
                <p className={styles.copyright}>Alpha Partners © {new Date().getFullYear()} {t('copyright_text')}</p>

            </div>
        </footer>
    );
}

Footer.propTypes = {
    setShowContactUsModal: PropTypes.func
};

export default Footer;