import PropTypes from 'prop-types';
import './style.scss';

function Modal({className, hasHeader, title, closeBtnClickHandler, children, buttonText, footerBtnClickHandler}) {
    return (
        <div className="modal-overlay">
            <div className={`${className} modal`}>
                {hasHeader &&
                    <div className="modal-header">
                        {title}

                        <button className="modal-btn-close" onClick={closeBtnClickHandler}>
                            <img src="./images/icons/close.svg" alt="close"/>
                        </button>
                    </div>
                }

                <div className="modal-body">
                    {children}
                </div>

                {buttonText &&
                    <div className="modal-footer">
                        <button className="modal-footer-btn" onClick={footerBtnClickHandler}
                                type="submit">
                            {buttonText}
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

Modal.propTypes = {
    hasHeader: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    closeBtnClickHandler: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    buttonText: PropTypes.string,
    footerBtnClickHandler: PropTypes.func,
};

Modal.defaultProps = {
    hasHeader: true,
    className: ''
};

export default Modal;