import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import Button from '../Button/Button';
import SectionWrapper from '../../components/SectionWrapper/SectionWrapper';

function Contact({setShowContactUsModal}) {
    const { t } = useTranslation();
        
    return (
        <SectionWrapper className="contact">
            <div className="inner-block">
                <h2>{t('contact_us_h1')}</h2>
                <p>{t('contact_us_h2')}</p>
                <Button secondary lg onClick={() => setShowContactUsModal(true)} text={t('contact_us')} />
            </div>
        </SectionWrapper>
    );
}

Contact.propTypes = {
    setShowContactUsModal: PropTypes.func
};

export default Contact;