import {useTranslation} from 'react-i18next';
import PageWrapper from '../components/PageWrapper/PageWrapper';

const Offers = () => {
    const {t} = useTranslation();

    return (
        <PageWrapper className="col-2"
                     background={'linear-gradient(0deg, rgb(2, 2, 30) 0%, rgba(37,3,23,1) 100%)'}>

            <div className="inner-content">
                <h1 className="page-wrapper-title">{t('offers_page_title')}</h1>
                <div dangerouslySetInnerHTML={{__html: t('offers_page_text')}}/>
            </div>
            <img src="./images/pages/offers.svg" alt="offers" className="inner-content-img" loading="lazy"/>
        </PageWrapper>
    );
};

export default Offers;