import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../locales/en.json';
import ruTranslation from '../locales/ru.json';

const resources = {
    en: {
        translation: enTranslation,
    },
    ru: {
        translation: ruTranslation,
    },
};

let initialLanguage = localStorage.getItem('selectedLanguage');
const browserLanguage = navigator.language.split('-')[0];

if (!initialLanguage) {
    initialLanguage = resources[browserLanguage] ? browserLanguage : 'en';
}

i18n
  .use(initReactI18next)
  .init({
    lng: initialLanguage,
    fallbackLng: 'en',
    resources,
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;